import React from "react"
import { PageProps } from "gatsby"

const NotFoundPage: React.FC<PageProps> = ({ location }) => {
  return (
   <div>
     404 Not Found
   </div>
  )
}

export default NotFoundPage
